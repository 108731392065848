<template>
  <section class="section section--info">
    <div class="section-inner">
      <b-container>
        <b-row align-h="center">
          <b-col lg="8">
            <h2>
              4in1clean je produkt, který je v Evropě jedinečný:
            </h2>
            <p>Podařilo se nám spojit 4 produkty v jedné lahvi.</p>
            <p>To je nenáročné na peněženku a chrání životní prostředí.</p>
            <p>Mnoho věcí lze vyčistit bez vody.</p>
            <p>Nastříkejte, nechte cca 10 sekund působit, setřete měkkým hadříkem a máte hotovo.</p>
            <p>Lze použít i v domácnostech a firmách.</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>

export default {
  name: 'InfoSection'
}
</script>

<style lang="scss">
@import "~assets/scss/vars/initial";
@import "~assets/scss/sections/info-section";
</style>
